import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import Mapbox from '../../mapbox'
// import { convertProvinceIdToText } from '../../mapbox/mapboxfn'
import LegendCard from '../../mapbox/LegendCard'
import Calculate from '../../util/calculate';
import Chart from '../../components/Chart';
import Sidetab from '../../components/Sidetab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TumbonAddr from '../../util/TumbonAddr'
import api from '../../util/api';
import '../index.css';
import students from '/images/students.png'
import { Modal, Divider, Select, Button, Table } from 'antd';

import marker_obec from '/images/marker/dot-obec.svg'
import marker_dla from '/images/marker/dot-dla.svg'
import marker_bpp from '/images/marker/dot-bpp.svg'
import marker_onab from '/images/marker/dot-onab.svg'
import marker_opec from '/images/marker/dot-opec.svg'
import marker_bkk from '/images/marker/dot-bkk.svg'

import imageSchoolS from '/images/krt-school-s.png';
import imageSchoolL from '/images/krt-school-l.png';
import imageSchoolX from '/images/krt-school-x.png';
import imageSchoolXL from '/images/krt-school-xl.png';

import imageBorder from '/images/krt-border.png';
import imageGround from '/images/krt-ground.png';
import imageHigh from '/images/krt-high.png';
import imageHill from '/images/krt-hill.png';
import imageIsload from '/images/krt-isload.png';

import obec_icon from '/images/home12.png'
import dla_icon from '/images/home10.png'
import bpp_icon from '/images/home8.png'
import opec_icon from '/images/home13.png'
import onab_icon from '/images/home11.png'
import bkk_icon from '/images/logo-bkk.png'
import sp_icon from '/images/home14.png'

import axios from 'axios';

const { Option } = Select;

const MAP_TEXT = {
  "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป": 'พื้นที่สูง',
  "พื้นราบ": 'พื้นที่ราบ',
  "บนเกาะ": 'พื้นที่เกาะ',
  "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง": 'พื้นที่เขา',
  "โรงเรียนที่เป็นพื้นที่สูงและชายแดน": 'พื้นที่ติดชายแดน',
}

var categories = [
  'อ.1',
  'อ.2',
  'อ.3',
  'ป.1',
  'ป.2',
  'ป.3',
  'ป.4',
  'ป.5',
  'ป.6',
  'ม.1',
  'ม.2',
  'ม.3',
  'ม.4',
  'ม.5',
  'ม.6',
  'ปวช.',
  'ปวส.',
  'อุดมศึกษา'
];

const Province = (props) => {
  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = useState(0);
  const [filter, setFilter] = useState({
    view: 'province',
    year: "2567",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null
  });

  const [modalListSchool, setModalListSchool] = useState({
    title: "",
    visible: false,
    resource: null
  });

  const [dataProvince, setDataProvince] = useState({})
  const [dataSchool, setDataSchool] = useState({
    total_school: 0,
    sum_sungkud_cct: 0,
    sum_sungkud_bpp: 0,
    sum_sungkud_dla: 0,
    sum_sungkud_opec: 0,
    sum_sungkud_onab: 0,
    sum_sungkud_bkk : 0,
    sum_sungkud_other: 0,

    sum_school_sizeXL: 0,
    sum_school_sizeX: 0,
    sum_school_sizeL: 0,
    sum_school_sizeS: 0,

    school_ground: 0,
    school_island: 0,
    school_high: 0,
    school_hill: 0,
    school_border: 0,
  })
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let user_data = await api.getProfileDb()
    let province = null
    if (user_data.ok) {
      let user_province_id = '10'
      if (user_data.profile.profile.provinceId) {
        user_province_id = user_data.profile.profile.provinceId
        province = TumbonAddr.find(item => item.provinceid === user_data.profile.profile.provinceId).provincename
      }
      let data_json = await api.getInitData(`/province/call-province-${user_province_id}`)
      data_json = data_json.data
      data_json.y25672 = data_json.y25671
      console.log('data_json)',data_json)
      filter.province_name = province
      setDataProvince(data_json)
      setFilter({ ...filter })
    }
  }, [])

  useEffect(() => {
    if (Object.values(dataProvince).length > 0) {
      let year = 'y' + filter.year + '2'
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, dataProvince[year]))
      let data_filter = dataProvince[year]
      if (filter.tumbon_name !== null) {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name && item.tumbon_name === filter.tumbon_name))]
      } else if (filter.city_name !== null) {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
      } else {
        data_filter = [...new Set(dataProvince[year].filter(item => item.province_name === filter.province_name))]
      }
      setDataSchool(mapSchoolData(data_filter))
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'school_obec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สพฐ.').map(item => ({ ...item, type: 'school_obec' })), icon: marker_obec },
        { id: 'school_dla', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'อปท.').map(item => ({ ...item, type: 'school_dla' })), icon: marker_dla },
        { id: 'school_bpp', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'ตชด.').map(item => ({ ...item, type: 'school_bpp' })), icon: marker_bpp },
        { id: 'school_opec', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'สช.').map(item => ({ ...item, type: 'school_opec' })), icon: marker_opec },
        { id: 'school_onab', layer: 'marker', data: data_filter.filter(item => item.sungkud === 'พศ.').map(item => ({ ...item, type: 'school_onab' })), icon: marker_onab }
      ])
      let data_legend = [
        { name: 'จำนวนโรงเรียน', value: response.data.reduce((acc, cur) => acc + cur.school, 0), unit: 'โรงเรียน' },
      ]
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      })
    }
  }, [filter, dataProvince])

  const mapSchoolData = (data) => {
    let result = {
      total_school: 0,
      sum_sungkud_cct: 0,
      sum_sungkud_bpp: 0,
      sum_sungkud_dla: 0,
      sum_sungkud_opec: 0,
      sum_sungkud_onab: 0,
      sum_sungkud_bkk: 0,
      sum_sungkud_other: 0,

      sum_school_sizeXL: 0,
      sum_school_sizeX: 0,
      sum_school_sizeL: 0,
      sum_school_sizeS: 0,

      school_ground: 0,
      school_island: 0,
      school_high: 0,
      school_hill: 0,
      school_border: 0,
    }
    data.forEach(x => {
      result['total_school'] += 1
      result['sum_sungkud_cct'] += (x.sungkud === 'สพฐ.' ? 1 : 0)
      result['sum_sungkud_bpp'] += (x.sungkud === 'ตชด.' ? 1 : 0)
      result['sum_sungkud_dla'] += (x.sungkud === 'อปท.' ? 1 : 0)
      result['sum_sungkud_opec'] += (x.sungkud === 'สช.' ? 1 : 0)
      result['sum_sungkud_onab'] += (x.sungkud === 'พศ.' ? 1 : 0)
      result['sum_sungkud_bkk'] += (x.sungkud === 'กทม.' ? 1 : 0)
      result['sum_sungkud_other'] += (x.sungkud === 'อื่นๆ.' ? 1 : 0)

      result['sum_school_sizeS'] += (x.school_size === 'ขนาดเล็ก' ? 1 : 0)
      result['sum_school_sizeL'] += (x.school_size === 'ขนาดกลาง' ? 1 : 0)
      result['sum_school_sizeX'] += (x.school_size === 'ขนาดใหญ่' ? 1 : 0)
      result['sum_school_sizeXL'] += (x.school_size === 'ขนาดใหญ่พิเศษ' ? 1 : 0)

      result['school_ground'] += (x.geographic_location === 'พื้นราบ' ? 1 : 0)
      result['school_island'] += (x.geographic_location === 'บนเกาะ' ? 1 : 0)
      result['school_high'] += (x.geographic_location === 'อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป' ? 1 : 0)
      result['school_hill'] += (x.geographic_location === 'โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง' ? 1 : 0)
      result['school_border'] += (x.geographic_location === 'โรงเรียนที่เป็นพื้นที่สูงและชายแดน' ? 1 : 0)
    })
    return (result)
  }

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { province: item, school: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], school: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], school: 0 } });
    data.forEach((item) => {
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['school'] ? province[index]['school'] = 1 : province[index]['school'] += 1
        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['school'] ? city[index]['school'] = 1 : city[index]['school'] += 1

        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['school'] ? tumbon[index]['school'] = 1 : tumbon[index]['school'] += 1
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['school'] ? tumbon[index]['school'] = 1 : tumbon[index]['school'] += 1
        }
        res = tumbon
      }
    })
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['school'] / element['school'])) {
        element['percent'] = 0
      } else {
        element['percent'] = (((element['school']) / result.reduce((acc, cur) => acc + (cur.school), 0)) * 100)
      }
      return element
    })

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.school)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.school)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.school)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, (element.school))
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    // if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      } else if (layer === 'tumbon' && filterBorder.tumbon) {
        _filter['view'] = 'tumbon'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = filterBorder.tumbon
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setModalSchool({
        visible: true,
        resource: 'school',
        ...property
      })
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === 'province') return;
    if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'tumbon') {
      _filter['view'] = 'city'
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  let tab_content = [
    {
      title: '',
      content: <div className='block w-full px-1 lg-px-16'>
        <div className='flex gap-4 justify-center'>
          <Select
            className='new-design-select'
            placeholder="ปีการศึกษา"
            showSearch
            value={filter.year}
            onChange={(val) => {
              setFilter({
                ...filter,
                year: val
              })
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              // ['2/2565', '1/2565', '2/2564', '1/2564', '2/2563', '1/2563', '2/2562', '1/2562', '2/2561']
              ['2566', '2565', '2564', '2563'].map(x => {
                return <Option value={x}>{x}</Option>
              })
            }
          </Select>
          <Select
            className='new-design-select'
            placeholder="อำเภอ"
            showSearch
            value={filter.city_name}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setFilter({
                  ...filter,
                  view: 'province',
                  city_name: null,
                  tumbon_name: null,
                })
              } else {
                setFilter({
                  ...filter,
                  view: 'city',
                  city_name: val,
                  tumbon_name: null,
                })
              }
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              [, ...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name).map(x => x.cityname))]
                .filter(x => x !== 'ไม่ระบุ')
                .sort()
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
          <Select
            className='new-design-select'
            placeholder="ตำบล"
            showSearch
            disabled={filter.city_name === 'ทั้งหมด' ? true : false}
            value={filter.tumbon_name}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setFilter({
                  ...filter,
                  view: 'city',
                  tumbon_name: null,
                })
              } else {
                setFilter({
                  ...filter,
                  view: 'tumbon',
                  tumbon_name: val
                })
              }
            }}
            style={{ width: 120, margin: 4, fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              filter.city_name !== 'ทั้งหมด' &&
              [, ...new Set(TumbonAddr.filter(item => item.provincename === filter.province_name && filter.city_name).map(x => x.tumbonname))]
                .filter(x => x !== 'ไม่ระบุ')
                .sort()
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
        </div>
        <div className='text-center my-4'>
          <h1 className='text-3xl font-bold'>จำนวนโรงเรียน</h1>
          <h2 className='text-2xl text-[#003171] font-bold'>{`"${filter.province_name}"`}</h2>

          <h1 className='text-5xl text-[#1611FF] font-bold'>{dataSchool.total_school ? dataSchool.total_school.toLocaleString('en-US') : 0}</h1>
        </div>
        {/* <div className='flex flex-col xl:flex-row w-full gap-4'>
          <div className="flex w-[100%] justify-between bg-white rounded-xl shadow-md py-2 px-6" >
            <img src={students} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
            <div>
              <div className='text-lg text-black pl-4 text-center'>  เด็กและเยาวชนในระบบการศึกษาขั้นพื้นฐาน</div>
              <div className='text-4xl text-[#106C87] text-center font-bold'> {`${data.students ? data.students.toLocaleString('en-US') : 0} คน`}</div>
            </div>
          </div>
          <div className="flex w-[100%] justify-between bg-white rounded-xl shadow-md py-2 px-6" >
            <img src={students} alt="Logoeef" width={'72'} height={'72'} style={{ alignSelf: 'center' }} />
            <div>
              <div className='text-lg text-black pl-4 text-center'>  เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3-17 ปี)</div>
              <div className='text-4xl text-[#F68631] text-center font-bold'> {`${data.oosc ? data.oosc.toLocaleString('en-US') : 0} คน`}</div>
            </div>
          </div>
        </div> */}
        <div className='w-full mx-auto'>
          <div className='pt-8'>
            <p className='text-xl mb-1'>
              โรงเรียนแบ่งตามสังกัด
            </p>
            <div className='flex flex-wrap gap-2 justify-center'>
              {
                [
                  { icon: obec_icon, key: 'สพฐ.', title: 'สพฐ.', value: (dataSchool.sum_sungkud_cct).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: dla_icon, key: 'อปท.', title: 'อปท.', value: (dataSchool.sum_sungkud_dla).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: bpp_icon, key: 'ตชด.', title: 'ตชด.', value: (dataSchool.sum_sungkud_bpp).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: opec_icon, key: 'สช.', title: 'สช.', value: (dataSchool.sum_sungkud_opec).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: onab_icon, key: 'พศ.', title: 'พศ.', value: (dataSchool.sum_sungkud_onab).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                  { icon: bkk_icon, key: 'กทม.', title: 'กทม.', value: (dataSchool.sum_sungkud_bkk).toLocaleString('en-Us'), unit: 'โรงเรียน' },
                ].map((ele, idx) => {
                  return <a onClick={() => {
                    let year = 'y' + filter.year + '2'
                    let dataTable = dataProvince[year].filter(item => ele.key === item.sungkud).map(item => {
                      return {
                        hostname: item.hostname,
                        sungkud: item.sungkud,
                        total_student: item.total_student,
                        school_size: item.school_size,
                        geographic_location: MAP_TEXT[item.geographic_location],
                      }
                    })
                    setModalListSchool({
                      ...modalListSchool,
                      title: `โรงเรียนตามสังกัด ${ele.key}`,
                      visible: true,
                      resource: dataTable
                    })
                  }} key={`${ele.title}-card-${idx}`} className='flex-1 text-black hover:text-gray-600'>
                    <div className='w-full py-4 border rounded-md shadow-lg text-center bg-white'>
                      <img className='mx-auto' src={ele.icon} width={60} />
                      <p className='my-1 text-xl xl:text-2xl'>{ele.title}</p>
                      <p className='my-1 text-2xl xl:text-3xl font-bold'>{ele.value}</p>
                      <p className='my-1 text-lg xl:text-xl'>{ele.unit}</p>
                    </div>
                  </a>
                })
              }
            </div>
          </div>
          <div className='pt-8'>
            <p className='text-xl mb-1'>
              โรงเรียนแบ่งตามขนาดโรงเรียน
            </p>
            <div className='grid grid-cols-12 gap-2'>
              {
                [
                  { icon: imageSchoolS, key: 'ขนาดเล็ก', title: 'รร.ขนาดเล็ก', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.sum_school_sizeS).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolL, key: 'ขนาดกลาง', title: 'รร.ขนาดกลาง', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.sum_school_sizeL).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolX, key: 'ขนาดใหญ่', title: 'รร.ขนาดใหญ่', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.sum_school_sizeX).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageSchoolXL, key: 'ขนาดใหญ่พิเศษ', title: 'รร.ขนาดใหญ่พิเศษ', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.sum_school_sizeXL).toLocaleString('en-Us'), unit: 'รร.' },
                ].map((ele, idx) => {
                  return <a onClick={() => {
                    let year = 'y' + filter.year + '2'
                    let dataTable = dataProvince[year].filter(item => ele.key === item.school_size).map(item => {
                      return {
                        hostname: item.hostname,
                        sungkud: item.sungkud,
                        total_student: item.total_student,
                        school_size: item.school_size,
                        geographic_location: MAP_TEXT[item.geographic_location],
                      }
                    })
                    setModalListSchool({
                      ...modalListSchool,
                      title: `โรงเรียน ${ele.key}`,
                      visible: true,
                      resource: dataTable
                    })
                  }} key={`${ele.title}-card-${idx}`} className='col-span-12 md:col-span-6 flex-col text-black hover:text-gray-600'>
                    <div className='relativef flex flex-row  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                      <img className="flex self-center xl:mx-4 w-[72px]" src={ele.icon} alt="images" />
                      <div className='flex flex-1 flex-col'>
                        <span className='text-xl font-bold text-center mb-0'>{ele.title}</span>
                        <span className='text-sm font-bold text-center'>{ele.subtitle}</span>
                        <p className='text-center text-[#038967] font-bold mb-0 text-2xl lg:text-3xl'>{`${ele.value} ${ele.unit}`}</p>
                      </div>
                    </div>
                  </a>
                })
              }
            </div>
          </div>
          <div className='pt-8'>
            <p className='text-xl mb-1 mt-5'>
              โรงเรียนแบ่งตามภูมิประเทศที่ตั้งโรงเรียน
            </p>
            <div className='grid grid-cols-12 gap-2'>
              {
                [
                  { icon: imageGround, key: 'พื้นราบ', title: 'พื้นที่ราบ', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.school_ground).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageHill, key: 'โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง', title: 'พื้นที่เขา', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.school_hill).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageHigh, key: 'อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป', title: 'พื้นที่สูง', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.school_high).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageBorder, key: 'โรงเรียนที่เป็นพื้นที่สูงและชายแดน', title: 'พื้นที่ติดชายแดน', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.school_border).toLocaleString('en-Us'), unit: 'รร.' },
                  { icon: imageIsload, key: 'บนเกาะ', title: 'พื้นที่เกาะ', subtitle: 'จำนวนโรงเรียน', value: (dataSchool.school_island).toLocaleString('en-Us'), unit: 'รร.' },
                ].map((ele, idx) => {
                  return <a onClick={() => {
                    let year = 'y' + filter.year + '2'
                    let dataTable = dataProvince[year].filter(item => ele.key === item.geographic_location).map(item => {
                      return {
                        hostname: item.hostname,
                        sungkud: item.sungkud,
                        total_student: item.total_student,
                        school_size: item.school_size,
                        geographic_location: MAP_TEXT[item.geographic_location],
                      }
                    })
                    setModalListSchool({
                      ...modalListSchool,
                      title: `โรงเรียนแบ่งตามภูมิประเทศ ${ele.title}`,
                      visible: true,
                      resource: dataTable
                    })
                  }} className={`col-span-12 lg:col-span-4 ${idx === 3 ? 'lg:col-start-3' : (idx === 4 ? 'lg:col-start-7' : '')} flex-col px-1 text-black hover:text-gray-600`}>
                    <div className='relativef flex flex-col  justify-center w-full rounded-lg overflow-hidden shadow-xl border-solid border-2 border-gray-200 p-2 bg-white'>
                      <span className='text-lg font-bold text-center mb-0'>{ele.title}</span>
                      <div className='flex flex-row justify-evenly '>
                        <img className="flex self-center" src={ele.icon} alt="image for banner" width={'56px'} style={{}} />
                        <div className='flex flex-col p-2'>
                          <span className='text-sm font-bold'>{ele.subtitle}</span>
                          <p className='text-center text-[#038967] font-bold mb-0 text-xl'>{`${ele.value} ${ele.unit}`}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                })
              }
            </div>
          </div>
        </div>
      </div>
    },
  ]

  return (
    <Layout Role={['partner', 'eef', 'partner_province']} isHideFooter>

      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalSchool({ visible: false })
        }}
      >
        <div className='text-3xl'>โรงเรียน{modalSchool?.hostname} <span className='text-xl'></span></div>
        <div className='text-xl'>ที่อยู่ ตำบล{modalSchool?.tumbon_name} อำเภอ{modalSchool?.city_name} จังหวัด{modalSchool?.province_name}</div>
        <Divider style={{ margin: 8 }} />
        {/* <div className='text-3xl m-2'>ได้รับทุนจากโครงการทุนเสมอภาค {modalSchool?.total_exjon} คน</div> */}

        <div className='grid grid-cols-12 gap-4 '>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลพื้นฐาน</div>
                <div className='text-lg '>สังกัด :  {modalSchool?.sungkud} </div>
                <div className='text-lg '>ขนาดโรงเรียน :  {modalSchool?.school_size} </div>
                <div className='text-lg '>ลักษณะภูมิประเทศ :  {modalSchool?.geographic_location ? MAP_TEXT[modalSchool?.geographic_location] : '-'} </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 lg:col-span-6 flex-col'>
            <div className='relative w-full '>
              <div className='w-full flex flex-col rounded-lg p-2'>
                <div className='text-xl text-green-500 '>ข้อมูลทรัพยากร</div>
                {modalSchool?.hostname &&
                  <>
                    <div className='text-lg '>จำนวนคอมพิวเตอร์ตั้งโต๊ะ :  {modalSchool?.desktop_computer_for_learning_can_be_used + modalSchool?.desktop_computer_for_manage_can_be_used}  </div>
                    <div className='text-lg '>จำนวนคอมพิเวเตอร Notebook : {modalSchool?.notebook_for_learning_can_be_used + modalSchool?.notebook_for_manage_can_be_used}  </div>
                    <div className='text-lg '>ไฟฟ้า : {modalSchool?.school_has_electricity ? 'มีไฟฟ้า' : 'ไม่มีไฟฟ้า'}  </div>
                    <div className='text-lg '>เขตบริการไฟฟ้า : {modalSchool?.school_has_electricity_service_area ? 'เขตบริการไฟฟ้า' : 'อยู่นอกเขตบริการไฟฟ้า'}  </div>
                    <div className='text-lg '>การเข้าถึง Internet : {modalSchool?.school_has_internet ? 'มีเข้าถึงอินเตอร์เน็ต' : 'ไม่มีการเข้าถึงอินเตอร์เน็ต'}  </div>
                    <div className='text-lg '>ข้อมูลแหล่งน้ำใช้ : {modalSchool?.school_has_water ? 'มี' : 'ไม่มี'}  </div>
                    <div className='text-lg '>ปัญหาอากาศ :  {
                      (
                        modalSchool.agricultural_chemistry_air_problem +
                        modalSchool.factory_air_problem +
                        modalSchool.traffic_air_problem +
                        modalSchool.garbage_air_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                    <div className='text-lg '>ปัญหาน้ำ :  {
                      (
                        modalSchool.agricultural_chemistry_water_problem +
                        modalSchool.factory_water_problem +
                        modalSchool.traffic_water_problem +
                        modalSchool.garbage_water_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                    <div className='text-lg '>ปัญหาเสียง :  {
                      (
                        modalSchool.agricultural_chemistry_noise_problem +
                        modalSchool.factory_noise_problem +
                        modalSchool.traffic_noise_problem +
                        modalSchool.garbage_noise_problem
                      ) > 0 ?
                        "มี" : "ไม่มี"
                    }
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      </Modal>

      <Modal
        width={'1000px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={modalListSchool.visible}
        centered={true}
        CancelText={'ปิด'}
        onCancel={() => {
          setModalListSchool({ visible: false })
        }}
      >
        <div className="flex flex-col gap-8">
          <h1 className="text-2xl semi-bold">{modalListSchool?.title}</h1>
          <Table
            size="small"
            bordered
            scroll={{ x: 800, y: 500 }}
            style={{ width: '100%' }}
            pagination={{ pageSize: 10 }}
            dataSource={modalListSchool?.resource}
            columns={[
              {
                title: 'ชื่อโรงเรียน',
                dataIndex: 'hostname',
                key: 'hostname',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      // style: { background: `hsl(118, 40%, ${50 - (30 * (text / record.sum)) + 50}%)`, textAlign: "right" }
                      style: { textAlign: "left" }
                    },
                    children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  };
                },
                width: 300,
                sorter: (a, b) => a.hostname - b.hostname,
              },
              {
                title: 'สังกัด',
                dataIndex: 'sungkud',
                key: 'sungkud',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "left" }
                    },
                    children: <div>{text ?? ""}</div>
                  };
                },
                width: 100,
                sorter: (a, b) => a.sungkud - b.sungkud,
              },
              {
                title: 'จำนวนนักเรียน',
                dataIndex: 'total_student',
                key: 'total_student',
                width: 100,
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "left" }
                    },
                    children: <div>{text ? text.toLocalString("en-Us") : 0}</div>
                  };
                },
                sorter: (a, b) => a.total_student - b.total_student,
              },
              {
                title: 'ขนาดโรงเรียน',
                dataIndex: 'school_size',
                key: 'school_size',
                width: 100,
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "left" }
                    },
                    children: <div>{text ?? ""}</div>
                  };
                },
                sorter: (a, b) => a.school_size - b.school_size,
              },
              {
                title: 'ภูมิประเทศที่ตั้งโรงเรียน',
                dataIndex: 'geographic_location',
                key: 'geographic_location',
                width: 100,
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "right" }
                    },
                    children: <div>{text ?? ""}</div>
                  };
                },
                sorter: (a, b) => a.geographic_location - b.geographic_location,
              },
            ]}
          >
          </Table>
        </div>
      </Modal>

      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            lng={99}
            lat={13.4}
            zoom={5.2}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== "province" ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนจำนวนโรงเรียน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>ประเภทสถานศึกษาตามสังกัด</span>
              <div class="flex flex-row">
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_obec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สพฐ.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_dla} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>อปท.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_bpp} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>ตชด.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_onab} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>พศ.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สช.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_opec} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>สช.</span>
                </div>
                <div class="flex flex-row text-center mr-2">
                  <img src={marker_bkk} style={{ width: '20px', height: '20px', margin: 'auto' }} />
                  <span className='center m-auto'>กทม.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title={<>สถานการณ์ด้านการศึกษาและการทำงาน <br />ของ กสศ. ในพื้นที่ของท่าน</>}
          tab={tab_content}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          titleRef=''
          subjectTitleRef='*ประมวลผล ณ เดือนสิงหาคม 2567'
        // handleChangeTab={handleChangeTab}
        />
      </div>
    </Layout>
  )
}

export default Province
